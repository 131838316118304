import React from "react";

import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";

import {Message} from "../types";
import {useAppDispatch, useAppSelector} from "../data/store";
import {markMessageAsRead} from "../data/mail";
import {Outlet, useNavigate} from "react-router-dom";
import {Mailbox} from "common";

const SHORT_DATE_FORMAT = Intl.DateTimeFormat("en-US", {
  dateStyle: "short",
});
const SHORT_TIME_FORMAT = Intl.DateTimeFormat("en-US", {
  timeStyle: "short",
});
const LONG_DATE_TIME_FORMAT = Intl.DateTimeFormat("en-US", {
  dateStyle: "full",
  timeStyle: "short",
});

const Messages = (params: {mailbox: Message[], inbox: boolean}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const markRead = async (message: Message) => {
    if (!message.read) {
      await dispatch(markMessageAsRead(message.id));
    }
  };

  const sendReply = (m: Message) => navigate(`reply-to-${m.sentFrom.toString()}/${m.pid}`);

  return (
    <Accordion flush>
      {params.mailbox.map((message) => (
        <Accordion.Item
          eventKey={message.id}
        >
          <Accordion.Header>
            {params.inbox ? (<>
              <span className="fw-lighter text-uppercase me-1">From:</span>
              <span className="me-3">{message.from}</span>
            </>) : (<>
              <span className="fw-lighter text-uppercase me-1">To:</span>
              <span className="me-3">{message.to}</span>
            </>)}
            <span className="fw-lighter text-uppercase me-1">Date:</span>
            <span className="me-3">{SHORT_DATE_FORMAT.format(message.sentOn)}</span>
            <span className="fw-lighter text-uppercase me-1">Time:</span>
            <span className="me-3">{SHORT_TIME_FORMAT.format(message.sentOn)}</span>
            {message.read === false && (
              <Badge pill>Unread</Badge>
            )}
          </Accordion.Header>
          <Accordion.Body onEntered={() => markRead(message)}>
          <Container>
            <Row className="mb-1">
              <Col sm={1} md={2} className="text-md-end text-nowrap fw-bolder">From:</Col>
              <Col>{message.from}</Col>
            </Row>
            <Row className="mb-1">
              <Col sm={1} md={2} className="text-md-end text-nowrap fw-bolder">To:</Col>
              <Col>{message.to}</Col>
            </Row>
            <Row className="mb-1">
              <Col sm={1} md={2} className="text-md-end text-nowrap fw-bolder">Sent On:</Col>
              <Col>{LONG_DATE_TIME_FORMAT.format(message.sentOn)}</Col>
            </Row>
            <Row>
              <Col sm={1} md={2} className="text-md-end text-nowrap fw-bolder">Message:</Col>
              <Col>{message.body}</Col>
            </Row>
            {message.mailbox === Mailbox.Inbox && (
              <Row className="mt-2">
                <Col sm={1} md={2} className="text-md-end text-nowrap fw-bolder"></Col>
                <Col><Button size="sm" onClick={() => sendReply(message)}>Send Reply</Button></Col>
              </Row>
            )}
          </Container>
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

const Mail = () => {
  const {inbox, outbox} = useAppSelector((state) => state.mail);

  return (<>
      <Card
          bg="primary"
          text="light"
          className="mb-3"
      >
          <Card.Header className="d-flex justify-content-between align-items-start">
              <div className="me-auto">
                  Inbox
              </div>
          </Card.Header>
          {inbox.length === 0 ? (
            <ListGroup className="list-group-flush">            
              <ListGroup.Item
                className="d-flex justify-content-between align-items-start"
              >
                You have not received any messages yet.
              </ListGroup.Item>
            </ListGroup>
          ) : (
            <Messages mailbox={inbox} inbox={true} />
          )}
      </Card>
    
      <Card
        bg="primary"
        text="light"
        className="mb-3"
    >
        <Card.Header className="d-flex justify-content-between align-items-start">
            <div className="me-auto">
                Outbox
            </div>
        </Card.Header>
        {outbox.length === 0 ? (
            <ListGroup className="list-group-flush">            
              <ListGroup.Item
                className="d-flex justify-content-between align-items-start"
              >
                You have not sent any messages yet.
              </ListGroup.Item>
            </ListGroup>
          ) : (
            <Messages mailbox={outbox} inbox={false} />
          )}
    </Card>
    <Outlet />
  </>);
};

export default Mail;
