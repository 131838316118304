"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageSender = exports.Mailbox = exports.AttendanceOptions = void 0;
var AttendanceOptions;
(function (AttendanceOptions) {
    AttendanceOptions["InPerson"] = "InPerson";
    AttendanceOptions["Remote"] = "Remote";
    AttendanceOptions["NotAttending"] = "NotAttending";
})(AttendanceOptions = exports.AttendanceOptions || (exports.AttendanceOptions = {}));
var Mailbox;
(function (Mailbox) {
    Mailbox["Inbox"] = "inbox";
    Mailbox["Outbox"] = "outbox";
})(Mailbox = exports.Mailbox || (exports.Mailbox = {}));
var MessageSender;
(function (MessageSender) {
    MessageSender["Giver"] = "giver";
    MessageSender["Receiver"] = "receiver";
    MessageSender["Admin"] = "admin";
})(MessageSender = exports.MessageSender || (exports.MessageSender = {}));
