import {doc, updateDoc} from "firebase/firestore";
import {db} from "./firebase";
import {GiftIdea, Receiver} from "../types";
import {ActionFunctionArgs, LoaderFunctionArgs} from "../App";
import {loadReceiver} from "./receivers";

const randomId = () => {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  return uint32.toString(16);
};

export type GiftIdeaLoaderData = {
  idea?: GiftIdea;
  receiver: Receiver;
};

export type GiftIdeaActionData = {
  description: string;
  link: string;
};

export async function loadGiftIdea(args: LoaderFunctionArgs): Promise<GiftIdeaLoaderData> {
  const {params} = args;
  const {
    idea: ideaId,
  } = params;

  const {receiver} = await loadReceiver(args);

  if (ideaId === undefined) {
    const msg = "Missing parameter 'ideaId'";
    console.error(`loadGiftIdea: ${msg}`);
    throw new Error(msg);
  }

  const idea = receiver.giftIdeas?.find((idea: GiftIdea) => idea.id === ideaId);

  return {
    idea,
    receiver,
  };
};

export async function modifyGiftIdeaAction({params, request, state, adminMode}: ActionFunctionArgs) {
  const {
    receiver: receiverId,
    idea: ideaId,
  } = params;
  const {
    description,
    link,
  } = Object.fromEntries(await request.formData()) as GiftIdeaActionData;

  let error;
  const {receivers} = adminMode ? state.allReceivers : state.myReceivers;

  if (receiverId === undefined) throw new Error("Missing required param receiverId");

  let giftIdeas;
  switch (request.method) {
    case "DELETE": {
      if (ideaId === undefined) throw new Error("Missing required param ideaId");
      
      const updated = receivers.find((r: Receiver) => r.pid === receiverId);
      if (updated === undefined) throw new Error(`Unable to find recevier ${receiverId}`);

      giftIdeas = updated.giftIdeas?.filter((i: GiftIdea) => i.id !== ideaId);
      break;
    }
    case "POST": {
      if (ideaId === undefined) throw new Error("Missing required param ideaId");

      const updated = receivers.find((r: Receiver) => r.pid === receiverId);
      if (updated === undefined) throw new Error(`Unable to find recevier ${receiverId}`);

      giftIdeas = [...updated.giftIdeas || []];
      giftIdeas[giftIdeas.findIndex((i) => i.id === ideaId)] = {
        id: ideaId,
        description,
        link,
      };
      break;
    }
    case "PUT": {
      const updated = receivers.find((r: Receiver) => r.pid === receiverId);
      if (updated === undefined) throw new Error(`Unable to find recevier ${receiverId}`);

      giftIdeas = [
        ...updated.giftIdeas || [],
        {
          id: randomId(),
          description,
          link,
        },
      ];
      break;
    }
    default:
      error = `Unsupported method ${request.method}`;
  }

  const receiverDocRef = doc(db, "gift-exchanges", String(state.giftExchange.currentGiftExchange?.gid), "receivers", receiverId);
  await updateDoc(receiverDocRef, {
    giftIdeas,
  });

  return {
    success: !error,
    error,
  };
}
