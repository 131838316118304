"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.today = exports.toISOTimeZone = exports.toISOTime = exports.toISODate = void 0;
const pad = (value) => {
    return value < 10 ? "0" + value : String(value);
};
const toISODate = (date) => `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
exports.toISODate = toISODate;
const toISOTime = (date) => `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`;
exports.toISOTime = toISOTime;
const toISOTimeZone = (date) => {
    const timeZoneOffset = date.getTimezoneOffset();
    return `${timeZoneOffset > 0 ? "-" : "+"}` +
        `${pad(Math.trunc(Math.abs(timeZoneOffset) / 60))}:` +
        `${pad(Math.abs(timeZoneOffset % 60))}`;
};
exports.toISOTimeZone = toISOTimeZone;
const DATE_TIME_FORMAT = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone: "America/New_York",
});
const today = () => {
    const now = DATE_TIME_FORMAT.format();
    const today = now.replace(
    // eslint-disable-next-line max-len
    /([0-9][0-9])\/([0-9][0-9])\/([0-9][0-9][0-9][0-9]), [0-9][0-9]:[0-9][0-9]:[0-9][0-9]/, "$3-$1-$2T00:00:00-05:00");
    return new Date(today);
};
exports.today = today;
