import React from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

const Loading = () => {
    return (
        <Container fluid className="min-vh-100">
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                <div className="p-3">
                    Santa is making a list...
                </div>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </Container>
    );
};

export default Loading;