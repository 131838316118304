import React, { useState } from "react";
import { useAppSelector } from "../data/store";
import { Participant } from "common";

import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Button from 'react-bootstrap/esm/Button';
import {
    Pencil,
} from 'react-bootstrap-icons';
import { Outlet, useNavigate } from "react-router-dom";

type FamilyGroupDictionary = { [familyGroup: string] : Participant[]; };

const Participants = () => {
    const [showInactive, setShowInactive] = useState(false);
    const navigate = useNavigate();
    
    let participants: Participant[] = useAppSelector((state) => state.participant.participants);
    if (!showInactive) {
        participants = participants.filter((p) => p.active);
    }

    const participantsByFamilyGroup = participants.reduce((participantsByFamilyGroup: FamilyGroupDictionary, p) => {
        const {familyGroup} = p;
        let participants = participantsByFamilyGroup[familyGroup];
        if (participants === undefined) {
            participantsByFamilyGroup[familyGroup] = participants = [];
        }
        participants.push(p);
        return participantsByFamilyGroup;
    }, {});

    const sortedFamilyGroups = Object.entries(participantsByFamilyGroup).sort((fg1, fg2) => fg1 < fg2 ? -1 : 1);

    return (
        <>
            {sortedFamilyGroups.map(([familyGroup, participants]) => (
                <Card
                    bg="primary"
                    text="light"
                    className="mb-3"
                    key={familyGroup}
                >
                    <Card.Header className="d-flex justify-content-between align-items-start">
                        <div className="me-auto">
                            Family Group {familyGroup}
                        </div>
                    </Card.Header>
                    <ListGroup className="list-group-flush">
                        {participants.map((p) => (
                            <ListGroup.Item
                                key={p.pid}
                                className="d-flex justify-content-between align-items-start"
                            >
                                {p.name}
                                {p.active || (
                                    <Badge bg="secondary" pill>Inactive</Badge>
                                )}
                                <ButtonGroup size="sm">
                                    <Button variant="outline-primary" onClick={() => navigate(`edit-participant/${p.pid}`)}><Pencil /></Button>
                                </ButtonGroup>
                            </ListGroup.Item>
                        ))}
                        <ListGroup.Item>
                            <ButtonGroup size="sm">
                                <Button variant="outline-primary" onClick={() => navigate(`new-participant/${familyGroup}`)}>
                                    Add Participant
                                </Button>
                                <Button variant="outline-primary" onClick={() => setShowInactive(!showInactive)}>
                                    {showInactive ? "Hide" : "Show"} Inactive
                                </Button>
                                <Button variant="outline-primary" onClick={() => navigate("create-gift-exchange")}>
                                    Create Gift Exchange
                                </Button>
                            </ButtonGroup>
                        </ListGroup.Item>
                    </ListGroup>
                </Card>
            ))}
            <Outlet />
        </>
    );
};

export default Participants;