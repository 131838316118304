import { logout } from "../data/auth";

const LoggedOut = () => {
    return (<>You have been logged out.</>);
};
LoggedOut.loader=async () => {
    await logout();
    return null;
};

export default LoggedOut;
