import React, {useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge';

import {
    ArrowRight,
} from 'react-bootstrap-icons';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
import Button from 'react-bootstrap/esm/Button';
import { Giver, GiftIdea, AttendanceOptions } from 'common';
import { useAppSelector } from '../data/store';
import { Outlet, useNavigate } from 'react-router-dom';
import AttendanceBadge from "../components/AttendanceBadge";

const GivingTo = () => {
    const giverState = useAppSelector((state) => state.giver);
    const {myGivers: givers} = giverState;
    const navigate = useNavigate();
    const giftExchangeState = useAppSelector((state) => state.giftExchange);

    useEffect(() => {
      if (!giftExchangeState.isInitializing && !giftExchangeState.isActive) {
        navigate("/");
      }
    }, [giftExchangeState.isActive, giftExchangeState.isInitializing, navigate]);

    return (<>
        {givers.map((giver: Giver) => (
            <Card
                bg="primary"
                text="light"
                className="mb-3"
                key={giver.pid}
            >
                <Card.Header className="d-flex justify-content-between align-items-start">
                    <div className="me-auto">
                        {giver.name} is giving to {giver.givingTo.name}
                    </div>
                    <AttendanceBadge participant={giver} />
                    <Badge bg="secondary" pill>${giver.spendingLimit} Limit</Badge>
                </Card.Header>
                <ListGroup className="list-group-flush">
                  {giver.attending !== undefined && (
                    <ListGroup.Item variant="warning">
                      {giver.attending === AttendanceOptions.InPerson && (<>
                        {giver.givingTo.name} is attending the gift exchange in person. If you are not attending
                        in person, please arrange to drop off or send the gifts to the address above.
                      </>)}
                      {giver.attending !== AttendanceOptions.InPerson && (<>
                        {giver.attending === AttendanceOptions.Remote && (
                          <>{giver.givingTo.name} is attending the gift exchange remotely. </>
                        )}
                        {giver.attending === AttendanceOptions.NotAttending && (
                          <>{giver.givingTo.name} will not be able to attend the gift exchange. </>
                        )}
                        Please deliver your gifts per the instructions below:
                        <blockquote className="deliveryInstructions"><pre>{giver.deliveryInstructions}</pre></blockquote></>
                      )}
                    </ListGroup.Item>
                  )}
                  {giver.giftIdeas?.map((idea: GiftIdea) => (
                        <ListGroup.Item
                            key={idea.id}
                            className="d-flex justify-content-between align-items-start"
                        >
                            {idea.description}
                            {idea.link && (
                                <a
                                    href={idea.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="icon-link inline-action-link"
                                >
                                    Open Link <ArrowRight/>
                                </a>
                            )}
                        </ListGroup.Item>
                    ))}
                    {(giver.giftIdeas?.length || 0) === 0 && (
                        <ListGroup.Item
                            className="d-flex justify-content-between align-items-start"
                        >
                            {giver.givingTo.name} has not submitted any ideas yet
                        </ListGroup.Item>
                    )}
                    <ListGroup.Item>
                        <ButtonGroup size="sm">
                            <Button variant="outline-primary" onClick={() => navigate(`send-message-to-receiver/${giver.pid}`)}>
                                Send Message to {giver.givingTo.name}
                            </Button>
                            <Button variant="outline-primary" onClick={() => navigate(`show-history/${giver.pid}`)}>
                                Show Giving History
                            </Button>
                        </ButtonGroup>
                    </ListGroup.Item>
                </ListGroup>
            </Card>
        ))}
        <Outlet />
    </>);
};

export default GivingTo;
