import React, { MouseEventHandler, useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {ExclamationCircleFill} from "react-bootstrap-icons";

import { Form as RouterForm, useActionData, useLoaderData, useNavigate } from "react-router-dom";
import { GiftIdeaLoaderData } from "../data/gift-ideas";
import Form from "react-bootstrap/Form";
import { ArrowRight } from "react-bootstrap-icons";
import { FormEventHandler } from "react";

declare type LowerCaseFormMethod = "post" | "put" | "delete";
declare type UpperCaseFormMethod = Uppercase<LowerCaseFormMethod>;
export declare type HTMLFormMethod = LowerCaseFormMethod | UpperCaseFormMethod;

type GiftIdeaProps = {
    method: HTMLFormMethod
};

const GiftIdea = ({method}: GiftIdeaProps) => {
    const navigate = useNavigate();
    const {
        idea,
    } = useLoaderData() as GiftIdeaLoaderData;
    const actionResult = useActionData() as any;
    const [validated, setValidated] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const isReadOnly = method === "DELETE" || method === "delete";

    const handleClose = useCallback(() => {
        navigate("..");
    }, [navigate]);

    useEffect(() => {
        if (actionResult?.success === true) {
          handleClose();
        } else {
          setIsProcessing(false);
        }
    }, [actionResult, handleClose]);

    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
          setIsProcessing(true);
        }
        setValidated(true);
    };

    useEffect(() => {
      updateTestLinkButton(document.getElementById("ideaLink") as HTMLInputElement);
    }, []);

    const updateTestLinkButton = (inputElement: HTMLInputElement) => {
      const testLinkButton = document.getElementById("testIdeaLink") as HTMLButtonElement;
      if (testLinkButton !== null) {
        testLinkButton.hidden =
          inputElement === null || inputElement.classList.contains("form-control-plaintext") ?
          true :
          inputElement.value === "" || !inputElement.checkValidity()
        ;
      }
    }

    const handleTestIdeaLinkButton: MouseEventHandler<HTMLButtonElement> = () => {
      const inputElement = document.getElementById("ideaLink") as HTMLInputElement;
      if (inputElement !== null && inputElement.checkValidity()) {
        window.open(inputElement.value);
      }
    };

  return (
        <Modal show={true} onHide={handleClose}>
            <Form as={RouterForm} method={method} noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(method === "PUT" || method === "put") && "New Gift Idea"}
                        {(method === "POST" || method === "post") && "Edit Gift Idea"}
                        {(method === "DELETE" || method === "delete") && "Delete Gift Idea?"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="ideaDescription">
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                            type="input"
                            name="description"
                            plaintext={isReadOnly}
                            readOnly={isReadOnly}
                            defaultValue={idea?.description}
                            required
                            disabled={isProcessing}
                            as="textarea"
                        />
                      <Form.Control.Feedback type="invalid">
                        A description is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    {(!isReadOnly || idea?.link) && (
                        <Form.Group className="mb-3" controlId="ideaLink">
                            <Form.Label>
                                URL (Optional)
                            </Form.Label>
                            <Form.Control
                                id="ideaLink"
                                type="url"
                                name="link"
                                plaintext={isReadOnly}
                                readOnly={isReadOnly}
                                defaultValue={idea?.link}
                                onChange={(event) => updateTestLinkButton(event.currentTarget as HTMLInputElement)}
                                disabled={isProcessing}
                            />
                            <Button
                              id="testIdeaLink"
                              onClick={handleTestIdeaLinkButton}
                              variant="link"
                              disabled={isProcessing}
                            >
                                Test Link <ArrowRight />
                            </Button>
                            <Form.Control.Feedback type="invalid">
                              The link is not a valid URL.
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {actionResult?.success === false && actionResult?.error && (
                      <Alert variant="danger"><ExclamationCircleFill /> {actionResult?.error}</Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={isProcessing}>
                        { isReadOnly ? "No, Keep It" : "Cancel" }
                    </Button>
                    <Button type="submit" variant="primary" disabled={isProcessing}>
                        { isReadOnly ? "Yes, Delete It" : "Save" }
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default GiftIdea;
