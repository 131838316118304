import React from "react";
import Container from "react-bootstrap/Container";

const NoGiftExchange = () => {
    return (
        <Container fluid className="min-vh-100">
            <div className="position-absolute top-50 start-50 translate-middle text-center">
                <img src="/closed.png" alt="Closed sign"></img>
                <div className="p-3">
                    Santa is still making a list and checking it twice.<br/>
                    Please try again later.
                </div>
            </div>
        </Container>
    );
};

export default NoGiftExchange;