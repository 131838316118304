import { ThunkAction, configureStore } from '@reduxjs/toolkit';
import {
    AnyAction,
    Dispatch,
    Middleware,
    StoreEnhancer,
    combineReducers,
} from 'redux';
import authReducer from './auth';
import giverReducer, { GiverActionsTypes, loadGiversEpic } from './my-givers';
import myReceiversReducer, { MyReceiverActionsTypes, loadMyReceiversEpic } from './my-receivers';
import giftExchangeReducer, { GiftExchangeActionsTypes, loadGiftExchangeEpic } from './giftExchange';
import participantReducer, { loadParticipantsEpic } from './participant';
import { Epic, StateObservable, combineEpics, createEpicMiddleware } from 'redux-observable';
import { Observable, catchError } from 'rxjs';
import { ParticipantActionsTypes } from './participant';
import mailReducer, {MailActionsTypes, loadMailEpic} from "./mail";
import {AuthState, GiftExchangeState, GiverState, MailState, ParticipantState, ReceiverState} from "../types";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import allReceiversReducer, {AllReceiverActionsTypes, loadAllReceiversEpic} from "./all-receivers";

export type RootState = {
  auth: AuthState,
  giftExchange: GiftExchangeState,
  participant: ParticipantState,
  giver: GiverState,
  myReceivers: ReceiverState,
  allReceivers: ReceiverState,
  mail: MailState,
};

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;

export type ActionTypes =
  MyReceiverActionsTypes |
  AllReceiverActionsTypes |
  GiverActionsTypes |
  ParticipantActionsTypes |
  GiftExchangeActionsTypes |
  MailActionsTypes
;

const epics: Epic<ActionTypes, ActionTypes, RootState>[] = [
    loadGiftExchangeEpic,
    loadGiversEpic,
    loadMyReceiversEpic,
    loadAllReceiversEpic,
    loadParticipantsEpic,
    loadMailEpic,
];
const epicMiddleware = createEpicMiddleware<ActionTypes, ActionTypes, RootState>();

const store = configureStore<RootState, ActionTypes, ReadonlyArray<Middleware<{}, RootState>>, readonly StoreEnhancer[]>({
    reducer: combineReducers({
        auth: authReducer,
        giftExchange: giftExchangeReducer,
        participant: participantReducer,
        giver: giverReducer,
        myReceivers: myReceiversReducer,
        allReceivers: allReceiversReducer,
        mail: mailReducer,
    }),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(epicMiddleware),
});

export const rootEpic = (action$: Observable<ActionTypes>, store$: StateObservable<RootState>, dependencies: any) =>
    combineEpics(...epics)(action$, store$, dependencies).pipe(
        catchError((error, source) => {
            console.error(error);
            return source;
        })
    );
epicMiddleware.run(rootEpic);

export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
type DispatchFunc = () => Dispatch<any>
export const useAppDispatch: DispatchFunc = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store;
