import {createSlice} from "@reduxjs/toolkit";

import {zip, Observable, of} from 'rxjs';
import {map, switchMap, filter, withLatestFrom} from 'rxjs/operators';

import {ParticipantState} from "../types";
import {Participant} from "common";
import {ActionTypes, RootState} from "./store";
import {updateUser} from "./auth";
import {updateGiftExchange} from "./giftExchange";
import {Epic} from "redux-observable";

import {db} from './firebase';
import {
  collection,
  doc,
  query,
  onSnapshot,
  updateDoc,
  deleteDoc,
  setDoc,
  getDoc,
} from "firebase/firestore";

import {ActionFunctionArgs, LoaderFunctionArgs} from "../App";


const initialState: ParticipantState = {
  participants: [],
  isInitializing: true,
};

export const slice = createSlice({
  name: 'participant',
  initialState,
  reducers: {
    updateParticipants: (state, action) => {
      const {participants: existingParticipants} = state;
      const {payload: newParticipants} = action;

      // Remove any dups
      state.participants = [
        ...newParticipants,
        ...existingParticipants.filter((ep) => newParticipants.findIndex((np: Participant) => np.pid === ep.pid) === -1)
      ].sort((p1: Participant, p2: Participant) => p1.name < p2.name ? -1 : 1);
    },
    deleteParticipants: (state, action) => {
      const {participants: existingParticipants} = state;
      const {payload: participantsToDelete} = action;

      // Remove participant if id is in the action list
      state.participants = existingParticipants.filter((p: Participant) => !participantsToDelete.includes(p.pid));
    },
    initComplete: (state) => {
      state.isInitializing = false;
    },
  },
});

export const {
  updateParticipants,
  deleteParticipants,
  initComplete,
} = slice.actions;

export type ParticipantActionsTypes =
  ReturnType<typeof updateParticipants> |
  ReturnType<typeof deleteParticipants> |
  ReturnType<typeof initComplete>;

const participantsCollection = collection(db, "participants");

export const loadParticipantsEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) => {
  const user$ = action$.pipe(filter(updateUser.match));
  const giftExchange$ = action$.pipe(filter(updateGiftExchange.match));
  return zip([user$, giftExchange$]).pipe(
    withLatestFrom(state$),
    map(([, state]) => state),
    filter((state) => !state.auth.isInitializing),
    filter((state) => state.auth.isLoggedIn),
    switchMap((state) => 
      state.auth.user?.isAdmin ?
      new Observable<ParticipantActionsTypes>((subscriber) => {
        const participantsQuery = query(participantsCollection);

        const unsubscribe = onSnapshot(participantsQuery, {
          next: (snapshot) => {
            const participantsToUpdate: Participant[] = [];
            const participantsToDelete: string[] = [];
            snapshot.docChanges().forEach((change) => {
              const {doc} = change;
              if (change.type === "removed") {
                participantsToDelete.push(doc.id);
              } else {
                const {name, active, familyGroup, access, givingHistory} = doc.data();
                participantsToUpdate.push({
                  pid: doc.id,
                  name,
                  active,
                  familyGroup,
                  access,
                  givingHistory,
                });
              }
            });
            if (participantsToUpdate.length > 0) {
              subscriber.next(updateParticipants(participantsToUpdate));
            }
            if (participantsToDelete.length > 0) {
              subscriber.next(deleteParticipants(participantsToDelete));
            }
            if (state.participant.isInitializing) {
              subscriber.next(initComplete());
            }
          },
          error: (error) => {
            console.error("Participants query errored", error);
            subscriber.error(error);
          },
          complete: () => {
            console.warn("Participants query closed");
            subscriber.complete();
          },
        });
        return unsubscribe;
      }) :
      of(initComplete())
    ),
  );
};

export type ParticipantLoaderData = {
  participant?: Participant;
  familyGroup?: string;
  familyGroups: string[];
};

export type ParticipantActionData = {
  pid: string,
  name: string,
  active: boolean,
  familyGroup: string,
  access: string[],
};

const extractFamilyGroups = (participants: Participant[], additionalFamilyGroup?: string) => {
  const familyGroups = participants.reduce((familyGroups: string[], p: Participant) => {
    if (!familyGroups.includes(p.familyGroup)) {
      familyGroups.push(p.familyGroup);
    }
    return familyGroups;
  }, []);
  if (additionalFamilyGroup !== undefined && !familyGroups.includes(additionalFamilyGroup)) {
    familyGroups.push(additionalFamilyGroup);
  }
  familyGroups.sort((fg1: string, fg2: string) => fg1 < fg2 ? -1 : 1);
  return familyGroups;
}

export const loadParticipant = (({params, state}: LoaderFunctionArgs): Promise<ParticipantLoaderData> =>
  new Promise((resolve, reject) => {
    const {
      participant: participantId,
    } = params;

    if (participantId === undefined) {
      const msg = "Missing parameter 'participant'";
      console.error(`loadParticipant: ${msg}`);
      return reject(msg);
    }

    const participant: Participant | undefined = state.participant.participants.find((r: Participant) => r.pid === participantId);
    if (participant === undefined) {
      const msg = `Unknown participant '${participantId}'`;
      console.error(`loadParticipant: ${msg}`);
      reject(msg);
    }

    const familyGroups = extractFamilyGroups(state.participant.participants);

    resolve({
      participant,
      familyGroups,
    });
  })
);

export const loadFamilyGroups = (({params, state}: LoaderFunctionArgs): Promise<ParticipantLoaderData> =>
  new Promise((resolve, reject) => {
    const {
      familyGroup,
    } = params;

    if (familyGroup === undefined) {
      const msg = "Missing parameter 'familyGroup'";
      console.error(`loadFamilyGroups: ${msg}`);
      return reject(msg);
    }

    const familyGroups = extractFamilyGroups(state.participant.participants, familyGroup);

    resolve({
      familyGroup,
      familyGroups,
    });
  })
);

export async function modifyParticipant({params, request, state, dispatch}: ActionFunctionArgs) {
  const {
    participant: participantId,
  } = params;
  const formData = await request.formData();
  const updatedParticipant: ParticipantActionData = {
    pid: formData.get("id") as string,
    name: formData.get("name") as string,
    active: Boolean(formData.get("active") as string),
    familyGroup: formData.get("familyGroup") as string,
    access: formData.getAll("access").filter((email) => email.toString().trim() !== "") as string[],
  };

  let error;

  switch (request.method) {
    case "DELETE": {
      if (participantId === undefined) throw new Error("Missing required param participantId");
      const participantRef = doc(participantsCollection, participantId);
      await deleteDoc(participantRef);
      break;
    }
    case "POST": {
      if (participantId === undefined) throw new Error("Missing required param participantId");
      const participantRef = doc(participantsCollection, participantId);
      await updateDoc(participantRef, {
        ...updatedParticipant,
      });
      break;
    }
    case "PUT": {
      const participantRef = doc(participantsCollection, updatedParticipant.pid);
      const lookupRef = await getDoc(participantRef);
      if (lookupRef.exists()) {
        error = `Participant with id "${updatedParticipant.pid}" already exists`;
      } else {
        await setDoc(participantRef, {
          ...updatedParticipant,
        });
      }
      break;
    }
    default:
      error = `Unsupported method ${request.method}`;
  }
  return {
    success: !error,
    error,
  };
}

export default slice.reducer;
