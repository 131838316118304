import React from "react";
import './App.css';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
  LoaderFunctionArgs as RouterLoaderFunctionArgs,
  LoaderFunction as RouterLoaderFunction,
  Params as RouterParams,
  ActionFunctionArgs as RouterActionFunctionArgs,
  ActionFunction as RouterActionFunction,
  defer,
  useRouteError,
  isRouteErrorResponse,
} from 'react-router-dom';

import GivingTo from './pages/GivingTo';
import GiftIdeas from './pages/GiftIdeas';

import ProtectedSiteLayout from './components/ProtectedSiteLayout';
import LoggedOut from './pages/LoggedOut';
import { useEffect } from 'react';
import UnprotectedSiteLayout from './components/UnprotectedSiteLayout';
import SendMessage from './pages/SendMessage';
import ShowHistory from './pages/ShowHistory';
import store, { AppDispatch, RootState, useAppDispatch, useAppSelector } from './data/store';
import { getCurrentUser, listenForUser } from './data/auth';
import GiftIdea from './pages/GiftIdea';
import { loadGiver } from './data/my-givers';
import { sendMessage } from './data/mail';
import Participants from './pages/Participants';
import {loadFamilyGroups, loadParticipant, modifyParticipant } from './data/participant';
import Participant from './pages/Participant';
import CreateGiftExchange from './pages/CreateGiftExchange';
import { createGiftExchange } from './data/giftExchange';
import AttendanceStatus from "./pages/AttendanceStatus";
import SiteLayout from "./components/SiteLayout";
import Mail from "./pages/Mail";
import {loadReceiver, modifyAttendanceStatusAction} from "./data/receivers";
import {loadGiftIdea, modifyGiftIdeaAction} from "./data/gift-ideas";

const isAppReady = (state: RootState) => 
  state.auth.isInitializing !== true &&
  state.giftExchange.isInitializing !== true
;
const isParticipantReady = (state: RootState) =>
  state.participant.isInitializing !== true;
const isGiverReady = (state: RootState) =>
  state.giver.isInitializing !== true;
const isMyReceiversReady = (state: RootState) =>
  state.myReceivers.isInitializing !== true;
const isAllReceiversReady = (state: RootState) =>
  state.allReceivers.isInitializing !== true;

const waitForState = (check: (state: RootState) => boolean) =>
  new Promise<void>((resolve) => {
    let state = store.getState();
    if (check(state)) {
      return resolve();
    }
    const unsubscribe = store.subscribe(() => {
      state = store.getState();
      if (check(state)) {
        unsubscribe();
        resolve();
      }
    })
  });

let appReady: Promise<void> | undefined = undefined;
export const waitForAppReady = (): Promise<void> => {
  if (appReady === undefined) {
    appReady = waitForState(isAppReady);
  }
  return appReady;
};

interface Params extends RouterParams<string> {
  receiver?: string;
  giver?: string;
  idea?: string;
}

export interface LoaderFunctionArgs extends RouterLoaderFunctionArgs {
  state: RootState;
  params: Params;
  adminMode: boolean;
}

export interface LoaderFunction {
  (args: LoaderFunctionArgs): Promise<Response> | Response | Promise<any> | any;
}

const wrapLoader = (fn: LoaderFunction, check?: (state: RootState) => boolean, adminMode = false): RouterLoaderFunction =>
async (args) => {
console.log("Waiting for app ready");
  await waitForAppReady();
console.log("app ready");
  if (check !== undefined) {
console.log("Waiting for state ready");
    await waitForState(check);
console.log("state ready");
  }
console.log("Calling fn");
  return fn({
    ...args,
    state: store.getState(),
    adminMode,
  });
};

export interface ActionFunctionArgs extends RouterActionFunctionArgs {
  state: RootState;
  dispatch: AppDispatch,
  params: Params;
  adminMode: boolean;
};

export interface ActionFunction {
  (args: ActionFunctionArgs): Promise<Response> | Response | Promise<any> | any;
}

const wrapAction = (fn: ActionFunction, adminMode = false): RouterActionFunction =>
  async (args) => {
    return fn({
      ...args,
      state: store.getState(),
      dispatch: store.dispatch,
      adminMode,
    });
  };

  const DefaultRoute = () => {
    const {
      isActive,
      isInitializing,
    } = useAppSelector((state) => state.giftExchange);
    const {
      user
    } = useAppSelector((state) => state.auth);

    if (isInitializing) return (<></>);

    return isActive && user !== undefined && user.canAccess.length > 0 ?
      (<Navigate replace to="/giving-to" />) :
      (<Navigate replace to="/admin" />);
  };

  const App = () => {
  const dispatch = useAppDispatch();

  const loadInitState = () => {
    const user = getCurrentUser();
    return defer({
        user,
        appReady: Promise.all([user, waitForAppReady()]),
    });
  }

  const ParticipantsOrReceivers = () => {
    const {
      isActive,
      isInitializing,
    } = useAppSelector((state) => state.giftExchange);

    if (isInitializing) return (<></>);

    if (isActive) {
      return (<GiftIdeas adminMode />);
    }

    return (<Participants />);
  };

  function RootBoundary() {
    const error = useRouteError();
  
    if (isRouteErrorResponse(error)) {
      if (error.status === 404) {
        return <SiteLayout>This page doesn't exist!</SiteLayout>;
      }
  
      if (error.status === 401) {
        return <SiteLayout>You aren't authorized to see this</SiteLayout>;
      }
    }
  
    return <SiteLayout>Oops! Something went wrong.</SiteLayout>;
  }

  const router = createBrowserRouter(
    createRoutesFromElements(<>
      <Route
        element={<ProtectedSiteLayout />}
        loader={loadInitState}
        errorElement={<RootBoundary />}
      >
        <Route index element={<DefaultRoute />} />
        <Route path="/" element={<DefaultRoute />} />
        <Route path="/giving-to" element={<GivingTo />}>
          <Route
            path="send-message-to-receiver/:giver"
            element={<SendMessage />}
            loader={wrapLoader(loadGiver, isGiverReady)}
            action={wrapAction(sendMessage)}
          />
          <Route
            path="show-history/:giver"
            element={<ShowHistory />}
            loader={wrapLoader(loadGiver, isGiverReady)}
          />
        </Route>
        <Route path="/gifts" element={<GiftIdeas />}>
          <Route
            path="new-idea/:receiver"
            element={<GiftIdea method='put' />}
            loader={wrapLoader(loadReceiver, isMyReceiversReady)}
            action={wrapAction(modifyGiftIdeaAction)}
          />
          <Route
            path="edit-idea/:receiver/:idea"
            element={<GiftIdea method='post' />}
            loader={wrapLoader(loadGiftIdea, isMyReceiversReady)}
            action={wrapAction(modifyGiftIdeaAction)}
          />
          <Route
            path="delete-idea/:receiver/:idea"
            element={<GiftIdea method='delete' />}
            loader={wrapLoader(loadGiftIdea, isMyReceiversReady)}
            action={wrapAction(modifyGiftIdeaAction)}
          />
          <Route
              path="send-message-to-giver/:receiver"
              element={<SendMessage />}
            loader={wrapLoader(loadReceiver, isMyReceiversReady)}
            action={wrapAction(sendMessage)}
          />
          <Route
            path="attendance-status/:receiver"
            element={<AttendanceStatus />}
            loader={wrapLoader(loadReceiver, isMyReceiversReady)}
            action={wrapAction(modifyAttendanceStatusAction)}
          />
        </Route>
        <Route path="/admin" element={<ParticipantsOrReceivers />}>
            <Route
              path="new-participant/:familyGroup"
              element={<Participant method='put' />}
              loader={wrapLoader(loadFamilyGroups, isParticipantReady, true)}
              action={wrapAction(modifyParticipant, true)}
            />
            <Route path="edit-participant/:participant"
              element={<Participant method='post' />}
              loader={wrapLoader(loadParticipant, isParticipantReady, true)}
              action={wrapAction(modifyParticipant, true)}
            />
            <Route path="create-gift-exchange"
              element={<CreateGiftExchange />}
              action={wrapAction(createGiftExchange, true)}
            />
            <Route
              path="new-idea/:receiver"
              element={<GiftIdea method='put' />}
              loader={wrapLoader(loadReceiver, isAllReceiversReady, true)}
              action={wrapAction(modifyGiftIdeaAction, true)}
            />
            <Route
              path="edit-idea/:receiver/:idea"
              element={<GiftIdea method='post' />}
              loader={wrapLoader(loadGiftIdea, isAllReceiversReady, true)}
              action={wrapAction(modifyGiftIdeaAction, true)}
            />
            <Route
              path="delete-idea/:receiver/:idea"
              element={<GiftIdea method='delete' />}
              loader={wrapLoader(loadGiftIdea, isAllReceiversReady, true)}
              action={wrapAction(modifyGiftIdeaAction, true)}
            />
            <Route
              path="attendance-status/:receiver"
              element={<AttendanceStatus />}
              loader={wrapLoader(loadReceiver, isAllReceiversReady, true)}
              action={wrapAction(modifyAttendanceStatusAction, true)}
            />
        </Route>
        <Route
          path="/mail"
          element={<Mail />}
        >
            <Route
              path="reply-to-giver/:receiver"
              element={<SendMessage />}
              loader={wrapLoader(loadReceiver, isMyReceiversReady)}
              action={wrapAction(sendMessage)}
            />
            <Route
              path="reply-to-receiver/:giver"
              element={<SendMessage />}
              loader={wrapLoader(loadGiver, isGiverReady)}
              action={wrapAction(sendMessage)}
            />
        </Route>
      </Route>
      <Route element={<UnprotectedSiteLayout />}>
        <Route path="/logout" element={<LoggedOut />} loader={LoggedOut.loader} />
      </Route>
      <Route path="*" element={<DefaultRoute />} />
    </>)
  );

  useEffect(() => {
    dispatch(listenForUser());
  }, [dispatch]);

  return (
    <RouterProvider router={router} />
  );
};

export default App;
