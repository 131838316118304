import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {combineLatest,  Observable, of} from 'rxjs';
import {map, switchMap, filter, withLatestFrom} from 'rxjs/operators';

import {Receiver, ReceiverState} from "../types";
import {ActionTypes, RootState} from "./store";
import {updateUser} from "./auth";
import {updateGiftExchange} from "./giftExchange";
import {Epic} from "redux-observable";
import {collection, onSnapshot} from "firebase/firestore";
import {db} from "./firebase";

const initialState: ReceiverState = {
  receivers: [],
  isInitializing: true,
};

export const slice = createSlice({
  name: 'allReceivers',
  initialState,
  reducers: {
    updateReceivers: (state, action: PayloadAction<Receiver[]>) => {
      const {receivers: existingReceivers} = state;
      const {payload: newReceivers} = action;

      // Remove any dups
      state.receivers = [
        ...newReceivers,
        ...existingReceivers.filter((ep) => newReceivers.findIndex((p) => p.pid === ep.pid) === -1)
      ].sort((r1: Receiver, r2: Receiver) => r1.name < r2.name ? -1 : 1);
      state.isInitializing = false;
    },
    deleteReceivers: (state, action: PayloadAction<string[]>) => {
      const {receivers: existingReceivers} = state;
      const {payload: receiversToDelete} = action;

      // Remove receiver if id is in the action list
      state.receivers = existingReceivers.filter((r: Receiver) => !receiversToDelete.includes(r.pid));
    },
    initComplete: (state) => {
      state.isInitializing = false;
    },
  },
});

export const {
  updateReceivers,
  deleteReceivers,
  initComplete,
} = slice.actions;

export type AllReceiverActionsTypes = 
  ReturnType<typeof updateReceivers> |
  ReturnType<typeof deleteReceivers> |
  ReturnType<typeof initComplete>
;

export const loadAllReceiversEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) => {
  const user$ = action$.pipe(filter(updateUser.match));
  const giftExchange$ = action$.pipe(filter(updateGiftExchange.match));
  return combineLatest([user$, giftExchange$]).pipe(
    withLatestFrom(state$),
    map(([,state]) => state),
    filter((state) => !state.auth.isInitializing && !state.giftExchange.isInitializing),
    filter((state) => state.giftExchange.isActive),
    switchMap((state) =>
      state.auth.user?.isAdmin ?
      new Observable<AllReceiverActionsTypes>((subscriber) => {
        const receiversCollection = collection(db, "gift-exchanges", String(state.giftExchange.currentGiftExchange?.gid), "receivers");

        const unsubscribe = onSnapshot(receiversCollection, {
          next: (snapshot) => {
            const receiversToUpdate: Receiver[] = [];
            const receiversToDelete: string[] = [];
            snapshot.docChanges().forEach((change) => {
              const {doc} = change;
              if (change.type === "removed") {
                receiversToDelete.push(doc.id);
              } else {
                const data = doc.data();
                const {name, giftIdeas, spendingLimit, attending, deliveryInstructions} = data;
                receiversToUpdate.push({
                  pid: doc.id,
                  name,
                  giftIdeas,
                  spendingLimit,
                  attending,
                  deliveryInstructions,
                  messages: [],
                });
              }
            });
            if (receiversToUpdate.length > 0) {
              subscriber.next(updateReceivers(receiversToUpdate));
            }
            if (receiversToDelete.length > 0) {
              subscriber.next(deleteReceivers(receiversToDelete));
            }
            if (state.allReceivers.isInitializing) {
              subscriber.next(initComplete());
            }
          },
          error: (error) => {
            console.error("All receivers query errored", error);
            subscriber.error(error);
          },
          complete: () => {
            console.warn("All receivers query closed");
            subscriber.complete();
          },
        });
        return unsubscribe;
      }) :
      of(initComplete())
    ),
  );
};

export default slice.reducer;
