import {createSlice} from "@reduxjs/toolkit";

import {Observable, combineLatest, of} from "rxjs";
import {map, switchMap, filter, withLatestFrom} from 'rxjs/operators';

import {GiverState} from "../types";
import {Giver} from "common";
import {LoaderFunctionArgs} from "../App";
import {ActionTypes, RootState} from "./store";
import {updateUser} from "./auth";
import {updateGiftExchange} from "./giftExchange";
import {Epic, StateObservable} from "redux-observable";
import {db} from "./firebase";
import {collection, onSnapshot, query, where} from "firebase/firestore";

const initialState: GiverState = {
  isInitializing: true,
  myGivers: [],
};

export const slice = createSlice({
  name: 'giver',
  initialState,
  reducers: {
    updateGivers: (state, action) => {
      const {myGivers: existingGivers} = state;
      const {payload: newGivers} = action;

      // Remove any dups
      state.myGivers = [
        ...newGivers,
        ...existingGivers.filter((ep) => newGivers.findIndex((g: Giver) => g.pid === ep.pid) === -1)
      ].sort((g1: Giver, g2: Giver) => g1.name < g2.name ? -1 : 1);
    },
    deleteGivers: (state, action) => {
      const {myGivers: existingGivers} = state;
      const {payload: giversToDelete} = action;

      // Remove giver if id is in the action list
      state.myGivers = existingGivers.filter((g) => !giversToDelete.includes(g.pid));
    },
    initComplete: (state) => {
      state.isInitializing = false;
    },
  },
});

export const {
  updateGivers,
  deleteGivers,
  initComplete,
} = slice.actions;

export type GiverActionsTypes =
  ReturnType<typeof updateGivers> |
  ReturnType<typeof deleteGivers> |
  ReturnType<typeof initComplete>;

export type StateActionsType =
  ReturnType<typeof updateGivers>
  ;

export const loadGiversEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$: StateObservable<RootState>) => {
  const user$ = action$.pipe(filter(updateUser.match));
  const giftExchange$ = action$.pipe(filter(updateGiftExchange.match));
  return combineLatest([user$, giftExchange$]).pipe(
    withLatestFrom(state$),
    map(([,state]) => state),
    filter((state) => !state.auth.isInitializing && !state.giftExchange.isInitializing),
    filter((state) => state.auth.isLoggedIn),
    filter((state) => state.giftExchange.isActive),
    switchMap((state) => 
      (state.auth.user?.canAccess?.length || 0) === 0 ?
      of(initComplete()) :
      new Observable<GiverActionsTypes>((subscriber) => {
        const {canAccess = []} = state.auth.user || {};
        const giversCollection = collection(db, "gift-exchanges", String(state.giftExchange.currentGiftExchange?.gid), "givers");
        const giverQuery = query(giversCollection, where("pid", "in", canAccess));

        const unsubscribe = onSnapshot(giverQuery, {
          next: (snapshot) => {
            const giversToUpdate: Giver[] = [];
            const giversToDelete: string[] = [];
            snapshot.docChanges().forEach((change) => {
              const {doc} = change;
              if (change.type === "removed") {
                giversToDelete.push(doc.id);
              } else {
                const giver = doc.data() as Giver;
                giversToUpdate.push({
                  ...giver,
                  messages: [],
                });
              }
            });
            if (giversToUpdate.length > 0) {
              subscriber.next(updateGivers(giversToUpdate));
            }
            if (giversToDelete.length > 0) {
              subscriber.next(deleteGivers(giversToDelete));
            }
            if (state.giver.isInitializing) {
              subscriber.next(initComplete());
            }
          },
          error: (error) => {
            console.error("Givers query errored", error);
            subscriber.error(error);
          },
          complete: () => {
            console.warn("Givers query closed");
            subscriber.complete();
          },
        });
        return unsubscribe;
      })
    ),
  );
};

export type GiverLoaderData = {
  giver: Giver;
};

export async function loadGiver({params, state}: LoaderFunctionArgs): Promise<GiverLoaderData> {
  return new Promise((resolve, reject) => {
    const {giver: giverId} = params;

    if (giverId === undefined) {
      const msg = "Missing parameter 'giver'";
      console.error(`loadGiver: ${msg}`);
      return reject(msg);
    }

    const giver = state.giver.myGivers.find((g: Giver) => g.pid === giverId);
    if (giver === undefined) {
      const msg = `Unknown giver '${giverId}'`;
      console.error(`loadGiver: ${msg}`);
      return reject(msg);
    }

    resolve({giver});
  });
};

export default slice.reducer;
