import React, { useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {ExclamationCircleFill} from "react-bootstrap-icons";

import { Form as RouterForm, useActionData, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { FormEventHandler } from "react";
import {toISODate} from "common";

declare type LowerCaseFormMethod = "post" | "put" | "delete";
declare type UpperCaseFormMethod = Uppercase<LowerCaseFormMethod>;
export declare type HTMLFormMethod = LowerCaseFormMethod | UpperCaseFormMethod;

const CreateGiftExchange = () => {
    const navigate = useNavigate();
    const actionResult = useActionData() as any;
    const [validated, setValidated] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);

    const handleClose = useCallback(() => {
        navigate("..");
    }, [navigate]);

    useEffect(() => {
        if (actionResult?.success === true) {
          navigate("/");
        } else {
          setIsProcessing(false);
        }
    }, [actionResult, handleClose, navigate]);

    type FormObject = {
        checkValidity: () => boolean;
    }

    const handleSubmit: FormEventHandler<FormObject> = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
          setIsProcessing(true);  
        }
        setValidated(true);
      };

    const now = new Date();

    return (
        <Modal show={true} onHide={handleClose}>
            <Form as={RouterForm} method="put" noValidate validated={validated} onSubmit={handleSubmit} name="participant">
                <Modal.Header closeButton>
                    <Modal.Title>Create Gift Exchange</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group className="mb-3" controlId="giftExchangeCelebrationDateTime">
                        <Form.Label>Celebration Date</Form.Label>
                        <Form.Control
                            type="datetime-local"
                            name="celebrationDateTime"
                            required={true}
                            min={`${toISODate(now)}T12:00}`}
                            defaultValue={`${toISODate(now)}T12:00`}
                            disabled={isProcessing}
                            as="input"
                        />
                      <Form.Control.Feedback type="invalid">
                        A valid date and time are required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="giftExchangeCelebrationLocation">
                        <Form.Label>Celebration Location</Form.Label>
                        <Form.Control
                            type="text"
                            name="celebrationLocation"
                            required={true}
                            rows={4}
                            disabled={isProcessing}
                            as="textarea"
                        />
                      <Form.Control.Feedback type="invalid">
                        The celebration location is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="giftExchangeSpendingLimit">
                        <Form.Label>Spending Limit</Form.Label>
                        <Form.Control
                            type="number"
                            name="spendingLimit"
                            required={true}
                            min="1"
                            defaultValue="50"
                            disabled={isProcessing}
                            as="input"
                        />
                      <Form.Control.Feedback type="invalid">
                        A postive number is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="giftExchangePastHistoryYearsExcluded">
                        <Form.Label>Past History Excluded (Years)</Form.Label>
                        <Form.Control
                            type="number"
                            name="pastHistoryYearsExcluded"
                            required={true}
                            min="0"
                            defaultValue="10"
                            disabled={isProcessing}
                            as="input"
                        />
                      <Form.Control.Feedback type="invalid">
                          A postive number or zero is required.
                      </Form.Control.Feedback>
                    </Form.Group>
                    {actionResult?.success === false && actionResult?.error && (
                      <Alert variant="danger"><ExclamationCircleFill /> {actionResult?.error}</Alert>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={isProcessing}>Cancel</Button>
                    <Button type="submit" variant="primary" disabled={isProcessing}>Create</Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateGiftExchange;
