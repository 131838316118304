import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GiftExchangeState, GiftExchange } from "../types";
import { ActionTypes, RootState } from "./store";
import { Epic } from "redux-observable";
import { DocumentData, QueryDocumentSnapshot, Timestamp, collection, onSnapshot, query, where } from "firebase/firestore";
import { db, functions } from "./firebase";
import { Observable } from "rxjs";
import { ActionFunctionArgs } from "../App";
import { httpsCallableFromURL } from "firebase/functions";
import {today, CreateGiftExchangeRequest} from "common";

const initialState: GiftExchangeState = {
    isInitializing: true,
    isActive: false,
};

export const slice = createSlice({
    name: 'giftExchange',
    initialState,
    reducers: {
        updateGiftExchange: (state: GiftExchangeState, action: PayloadAction<GiftExchange | undefined>) => {
            state.currentGiftExchange = action.payload;
            state.isActive = action.payload !== undefined;
            state.isInitializing = false;
        },
    },
});

export const {
    updateGiftExchange,
} = slice.actions;

export type GiftExchangeActionsTypes = ReturnType<typeof updateGiftExchange>;

const giftExchangesCollection = collection(db, "gift-exchanges");

const convertFirebaseDocToGiftExchange = (doc: QueryDocumentSnapshot<DocumentData>) => {
  if (!doc.exists) {
    return undefined;
  }

  const {
    celebrationLocation,
    celebrationDateTime: celebrationDateTimeAsTimestamp,
  } = doc.data();
  return {
    gid: doc.id,
    celebrationLocation,
    celebrationDateTime: new Date(celebrationDateTimeAsTimestamp.seconds * 1000),
  };
};

export const loadGiftExchangeEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) => new Observable<GiftExchangeActionsTypes>((subscriber) => {
    const giftExchangeQuery = query(giftExchangesCollection, where("celebrationDateTime", ">=", Timestamp.fromDate(today())));

    const unsubscribe = onSnapshot(giftExchangeQuery, {
        next: (snapshot) => {
          let giftExchange: GiftExchange | undefined = undefined;
          if (!snapshot.empty) {
            giftExchange = convertFirebaseDocToGiftExchange(snapshot.docs[0]);
          }
          subscriber.next(updateGiftExchange(giftExchange));
        },
        error: (error) => {
            console.error("Gift Exchange query errored", error);
            subscriber.error(error);
        },
        complete: () => {
            console.warn("Gift Exchange query closed");
            subscriber.complete();
        },
    });
    return unsubscribe;
});

const createGiftExchangeFunction = httpsCallableFromURL<CreateGiftExchangeRequest, void>(
    functions,
    '/api/createGiftExchange',
);

export async function createGiftExchange({ params, request, state, dispatch }: ActionFunctionArgs) {
    const formData = await request.formData();
    const giftExchange: CreateGiftExchangeRequest = {
        celebrationDateTimeAsISO: new Date(Date.parse(formData.get("celebrationDateTime") as string)).toISOString(),
        celebrationLocation: formData.get("celebrationLocation") as string,
        spendingLimit: Number(formData.get("spendingLimit")),
        pastHistoryYearsExcluded: Number(formData.get("pastHistoryYearsExcluded")),
    };

    let error;

    switch (request.method) {
        case "PUT": {
            console.log(giftExchange);
            const result = await createGiftExchangeFunction(giftExchange);
            console.log(result);
            break;
        }
        default:
            error = `Unsupported method ${request.method}`;
    }
    return {
        success: !error,
        error,
    };
}

export default slice.reducer;
