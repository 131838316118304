import { useEffect } from "react";
import { login } from "../data/auth";
import SiteLayout from "./SiteLayout";
import Loading from "./Loading";

const Login = () => {
    useEffect(() => {
        login();
    // eslint-disable-next-line react-hooks/exhaustive-deps  
}, []);

    return (
        <SiteLayout>
            <Loading />
        </SiteLayout>
    );
};

export default Login;
