import React from 'react';

import SiteLayout from './SiteLayout';
import Loading from './Loading';
import { Await, Outlet, useLoaderData } from 'react-router-dom';
import { User } from '../types';
import Login from './Login';
import NoGiftExchange from './NoGiftExchange';
import { RootState, useAppSelector } from '../data/store';
import { GiftExchangeState } from '../types';

export interface ProtectedSiteLayoutData {
    user: Promise<User>;
    appReady: Promise<[User]>;
};

const LoadGiftExchange = () => {
    const {appReady} = useLoaderData() as ProtectedSiteLayoutData;
    const giftExchange = useAppSelector((state: RootState) => state.giftExchange as GiftExchangeState);

    if (giftExchange.isInitializing) {
        return (<Loading />);
    }

    if (giftExchange.isActive) {
        return (<SiteLayout><Outlet /></SiteLayout>);
    }

    return (
        <React.Suspense fallback={(<Loading />)}>
            <Await resolve={appReady}>
                {([user]) => user.isAdmin ? (<SiteLayout><Outlet /></SiteLayout>) : (<NoGiftExchange />)}
            </Await>
        </React.Suspense>
    );
};

const ProtectedSiteLayout = () => {
    const {user} = useLoaderData() as ProtectedSiteLayoutData;

    return (
        <React.Suspense fallback={(<Loading />)}>
            <Await resolve={user} errorElement={<Login />}>
                {(user) => (
                    <LoadGiftExchange />
                )}
            </Await>
        </React.Suspense>
    );
};

export default ProtectedSiteLayout;
