import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {signInWithRedirect, signOut, getRedirectResult, onAuthStateChanged} from 'firebase/auth';
import {auth, authProvider} from './firebase';

import {AuthState, User} from "../types";
import { AppThunk } from './store';

const initialState: AuthState = {
    isInitializing: true,
    isLoggedIn: false,
    user: undefined,
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateUser: (state: AuthState, action: PayloadAction<User|undefined>) => {
        const {payload: user} = action;
        state.user = user;
        state.isLoggedIn = user !== undefined;
        state.isInitializing = false;
    },
  },
});

export const {
    updateUser,
} = slice.actions;

export const getCurrentUser = async (): Promise<User> => {
    try {
        await getRedirectResult(auth);
        if (auth.currentUser !== null) {
            const idToken = await auth.currentUser.getIdTokenResult();
            const {email, displayName} = auth.currentUser;
            const {isAdmin = false, canAccess = []} = idToken.claims;
            return {email, displayName, isAdmin, canAccess};
        }
    } catch(err) {
        console.error("Error checking if user is logged in", err);
    }
    throw new Error("Not logged in");
};

export const login = async () => {
    await signInWithRedirect(auth, authProvider);
};

export const logout = async () => {
    await signOut(auth);
};

export const listenForUser = (): AppThunk => async (dispatch) => {
    const unsubscribe = onAuthStateChanged(auth, {
        next: async (newUser) => {
            let user = undefined;
            if (newUser) {
                const idToken = await newUser.getIdTokenResult();
                const {email, displayName} = newUser;
                const {isAdmin = false, canAccess = []} = idToken.claims;
                user = {email, displayName, isAdmin, canAccess};
            }
            dispatch(updateUser(user));
        },
        error: (err) => {
            console.log("Login auto state error", err);
            unsubscribe();
        },
        complete: () => unsubscribe(),
    });
};

export type StateActionsType = 
    ReturnType<typeof updateUser>
;

export default slice.reducer;
