import React, { FormEventHandler, useCallback, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Form as RouterForm, useActionData, useLoaderData, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { MessageLoaderData } from "../data/mail";
import { ExclamationCircleFill } from "react-bootstrap-icons";


const SendMessage = () => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const {
        receiver,
        giver,
    } = useLoaderData() as MessageLoaderData;
    const actionResult = useActionData() as any;
    const [isProcessing, setIsProcessing] = useState(false);

    const handleClose = useCallback(() => {
        navigate("..");
    }, [navigate]);

    useEffect(() => {
        if (actionResult?.success === true) {
            handleClose();
        } else {
          setIsProcessing(false);
        }
    }, [actionResult, handleClose]);

    type FormObject = {
        checkValidity: () => boolean;
    }

    const handleSubmit: FormEventHandler<FormObject> = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
          setIsProcessing(true);
        }
        setValidated(true);
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Form as={RouterForm} method="post" noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Group controlId="messageFrom" as={Row}>
                    <Form.Label column sm={2}>From:</Form.Label>
                    <Col>
                      <Form.Control
                          type="input"
                          plaintext
                          readOnly={true}
                          defaultValue={giver ? giver.name : receiver?.name}
                      />
                    </Col>
                </Form.Group>
                <Form.Group controlId="messageTo" as={Row}>
                    <Form.Label column sm={2}>To:</Form.Label>
                    <Col>
                      <Form.Control
                          type="input"
                          plaintext
                          readOnly={true}
                          defaultValue={giver ? giver.givingTo.name : "Secret Santa"}
                      />
                    </Col>
                </Form.Group>
                <Form.Group controlId="messageBody">
                    <Form.Label>
                        Message:
                    </Form.Label>
                    <Form.Control
                        type="input"
                        name="body"
                        as="textarea"
                        rows={4}
                        required
                        minLength={1}
                        disabled={isProcessing}
                    />
                    <Form.Control.Feedback type="invalid">
                      A message is required.
                    </Form.Control.Feedback>
                    {giver && (
                      <Form.Text>
                        The person who you are sending this message to does not know your identity. Avoid including
                        anything that will reveal who you are.
                      </Form.Text>
                    )}
                </Form.Group>
                {actionResult?.success === false && actionResult?.error && (
                  <Alert variant="danger"><ExclamationCircleFill /> {actionResult?.error}</Alert>
                )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose} disabled={isProcessing}>
                        Cancel
                    </Button>
                    <Button variant="primary" type="submit" disabled={isProcessing}>
                        Send Message
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default SendMessage;
