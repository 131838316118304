import React, {useState, PropsWithChildren, useEffect} from 'react';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Alert from 'react-bootstrap/Alert';
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import Button from "react-bootstrap/Button";

import {Tree} from 'react-bootstrap-icons';

import {LinkContainer} from 'react-router-bootstrap';
import {Link, useNavigate} from 'react-router-dom';

import {useAppSelector} from '../data/store';

const DATE_FORMATTER = Intl.DateTimeFormat("us-en", {
  weekday: "long",
  month: "long",
  day: "numeric",
  year: "numeric",
});

const TIME_FORMATTER = Intl.DateTimeFormat("us-en", {
  hour: "numeric",
  hour12: true,
  minute: "2-digit",
});

const year = new Date().getFullYear();

const SiteLayout = (props: PropsWithChildren) => {
  const authState = useAppSelector((state) => state.auth);
  const giftExchangeState = useAppSelector((state) => state.giftExchange);
  const mailState = useAppSelector((state) => state.mail);
  const {
    isLoggedIn,
    user,
  } = authState;
  const {
    currentGiftExchange,
    isActive,
  } = giftExchangeState;
  const {
    unreadMessageCount,
  } = mailState;
  const [expanded, setExpanded] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setShowToast(unreadMessageCount > 0);
  }, [unreadMessageCount]);

  return (
    <>
      <Navbar id="page-header" bg="primary" variant="dark" fixed="top" expand="md" expanded={expanded}>
        <Container>
          <Navbar.Brand>
            <Tree /> Gift Exchange
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar" onClick={() => setExpanded(!expanded)} />
          <Navbar.Collapse id="navbar">
            <Nav className="me-auto">
              {isLoggedIn && isActive && user !== undefined && user.canAccess.length > 0 && (<>
                <LinkContainer to="/giving-to">
                  <Nav.Link href="/giving-to" onClick={() => setExpanded(false)}>Giving To</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/gifts">
                  <Nav.Link href="/gifts" onClick={() => setExpanded(false)}>Your Gift Ideas</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/mail">
                  <Nav.Link href="/mail" onClick={() => setExpanded(false)}>Messages</Nav.Link>
                </LinkContainer>
              </>)}
              {user?.isAdmin && (
                <LinkContainer to="/admin">
                  <Nav.Link href="/admin" onClick={() => setExpanded(false)}>{isActive ? "Admin" : "Create Gift Exchange"}</Nav.Link>
                </LinkContainer>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className="p-3">
        {currentGiftExchange?.celebrationDateTime && (
          <Alert variant="success">
            <Alert.Heading>Merry Christmas!</Alert.Heading>
              The exchange will be held on {DATE_FORMATTER.format(currentGiftExchange.celebrationDateTime)} at {TIME_FORMATTER.format(currentGiftExchange.celebrationDateTime)}.  Location:<br/>
              <blockquote className="welcomeMessage"><pre>
                {currentGiftExchange.celebrationLocation}
              </pre></blockquote>
          </Alert>
        )}
        {props.children}
      </Container>
      <div className="home-link-list pb-2" id="footer-link-list">
        <ul>
          {(isLoggedIn && (<>
            {isActive && user !== undefined && user.canAccess.length > 0 && (
              <>
                <li><Link to="/giving-to">Giving To</Link></li>
                <li><Link to="/gifts">Your Gift Ideas</Link></li>
                <li><Link to="/mail">Messages</Link></li>
              </>
            )}
            {user?.isAdmin && (
                <li><Link to="/admin">{isActive ? "Admin" : "Create Gift Exchange"}</Link></li>
            )}
            <li><Link to="/logout">Logout</Link></li>
          </>)) || (<>
            <li><Link to="/">Login</Link></li>
          </>)}
        </ul>
      </div>
      <hr />
      <div className="ts-6 text-center my-2" id="footer-legal">
        &#169; {year} Christopher Hilt<br />
        This site is protected by reCAPTCHA and the Google<br />
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
        &nbsp;and&nbsp;<a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
      </div>
      <ToastContainer
        className="p-3 toast-container"
        position="top-end"
      >
        <Toast show={showToast} autohide={true} delay={10000} onClose={() => setShowToast(false)} >
          <Toast.Header>
            <strong className="me-auto">Unread Messages</strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body>
            <p>You have {unreadMessageCount} unread {unreadMessageCount === 1 ? "message" : "messages"}.</p>
            <Button variant="primary" size="sm" onClick={() => {setShowToast(false); navigate("/mail")}}>Open Messages</Button>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </>);

};

export default SiteLayout;
