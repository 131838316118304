import React, {useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import {ExclamationCircleFill} from "react-bootstrap-icons"
import {Form as RouterForm, useActionData, useLoaderData, useNavigate} from "react-router-dom";
import {ParticipantLoaderData} from "../data/participant";
import Form from "react-bootstrap/Form";
import {FormEventHandler} from "react";
import {useAppSelector} from "../data/store";

declare type LowerCaseFormMethod = "post" | "put" | "delete";
declare type UpperCaseFormMethod = Uppercase<LowerCaseFormMethod>;
export declare type HTMLFormMethod = LowerCaseFormMethod | UpperCaseFormMethod;

type ParticipantProps = {
  method: HTMLFormMethod
};

const Participant = ({method}: ParticipantProps) => {
  const navigate = useNavigate();
  const {
    participant,
    familyGroup,
    familyGroups,
  } = useLoaderData() as ParticipantLoaderData;
  const actionResult = useActionData() as any;
  const [validated, setValidated] = useState(false);
  const {isActive: isGiftExchangeActive} = useAppSelector((state) => state.giftExchange);
  const [isProcessing, setIsProcessing] = useState(false);

  const isReadOnly = method === "DELETE" || method === "delete";
  const isNew = method === "PUT" || method === "put";

  const handleClose = useCallback(() => {
    navigate("..");
  }, [navigate]);

  useEffect(() => {
    if (actionResult?.success === true) {
      handleClose();
    }
    setIsProcessing(false);
  }, [actionResult, handleClose]);

  type FormObject = {
    checkValidity: () => boolean;
  }

  const handleSubmit: FormEventHandler<FormObject> = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    setIsProcessing(true);
  };

  const addEmail = () => {
    const emailElem = document.createElement("input");
    emailElem.name = "access";
    emailElem.type = "input";
    emailElem.className = "form-control";
    emailElem.value = "";
    document.getElementById("participantAccess")?.insertBefore(emailElem, document.getElementById("new-email-button"))
  };

  const familyGroupChangeHandler = () => {
    const familyGroupSelect = document.getElementById("familyGroupSelect") as HTMLSelectElement;
    const familyGroupCustom = document.getElementById("familyGroupCustom") as HTMLInputElement;
    const familyGroup = document.getElementById("familyGroup") as HTMLInputElement;
    if (familyGroupSelect.value === "") {
      familyGroupCustom.hidden = false;
      familyGroup.value = familyGroupCustom.value;
    } else {
      familyGroupCustom.hidden = true;
      familyGroup.value = familyGroupSelect.value;
      familyGroupCustom.value = familyGroupSelect.value;
    }
  };

  return (
    <Modal show={true} onHide={handleClose}>
      <Form as={RouterForm} method={method} noValidate validated={validated} onSubmit={handleSubmit} name="participant">
        <Modal.Header closeButton>
          <Modal.Title>
            {(method === "PUT" || method === "put") && "New Participant"}
            {(method === "POST" || method === "post") && "Edit Participant"}
            {(method === "DELETE" || method === "delete") && "Delete Participant?"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isGiftExchangeActive ? (
            <input type="hidden" name="id" value={participant?.pid} />
          ) : (
              <Form.Group className="mb-3" controlId="participantId">
              <Form.Label>ID</Form.Label>
              <Form.Control
                type="input"
                name="id"
                plaintext={!isNew}
                readOnly={!isNew}
                defaultValue={participant?.pid}
                required
                as="input"
              />
              <Form.Control.Feedback type="invalid">
                An ID is required.
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group className="mb-3" controlId="participantName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="input"
              name="name"
              plaintext={isReadOnly}
              readOnly={isReadOnly}
              defaultValue={participant?.name}
              required
              as="input"
            />
            <Form.Control.Feedback type="invalid">
              A name is required.
            </Form.Control.Feedback>
          </Form.Group>
          {isGiftExchangeActive ? (
            <input type="hidden" name="active" value={String(participant?.active)} />
            ) : (
              <Form.Group className="mb-3" controlId="participantActive">
                <Form.Label>Is Active</Form.Label>
                <Form.Check
                  type="switch"
                  name="active"
                  disabled={isReadOnly || isGiftExchangeActive}
                  defaultChecked={participant?.active || isNew}
                />
              </Form.Group>
          )}
          {isGiftExchangeActive ? (
            <input type="hidden" name="familyGroup" value={participant?.familyGroup} />
          ) : (
            <Form.Group className="mb-3" controlId="participantFamilyGroup">
              <Form.Label>Family Group</Form.Label>
              <Form.Select
                name="familyGroupSelect"
                id="familyGroupSelect"
                disabled={isReadOnly || isGiftExchangeActive}
                defaultValue={participant?.familyGroup || familyGroup}
                onChange={familyGroupChangeHandler}
              >
                <option value="">Create New Group...</option>
                {familyGroups.map((familyGroup => (
                  <option value={familyGroup} key={familyGroup}>{familyGroup}</option>
                )))}
              </Form.Select>
              <Form.Control
                type="input"
                name="familyGroupCustom"
                id="familyGroupCustom"
                plaintext={isReadOnly}
                readOnly={isReadOnly}
                hidden={true}
                required
                as="input"
                defaultValue={participant?.familyGroup || familyGroup}
                onChange={familyGroupChangeHandler}
              />
              <Form.Control.Feedback type="invalid">
                A family group name is required.
              </Form.Control.Feedback>
              <input
                name="familyGroup"
                id="familyGroup"
                type="hidden"
                value={participant?.familyGroup || familyGroup}
              />
            </Form.Group>
          )}
          <Form.Group id="participantAccess" className="mb-3" controlId="participantAccess">
            <Form.Label>Access</Form.Label>
            {participant?.access.map(((email, key) => (
              <Form.Control
                key={`email-${key}`}
                type="input"
                name="access"
                plaintext={isReadOnly}
                readOnly={isReadOnly}
                defaultValue={email}
                as="input"
              />
            )))}
            <div id="new-email-button">
              <Button
                variant="secondary"
                size="sm"
                onClick={() => addEmail()}
              >Add email</Button>
            </div>
          </Form.Group>
          {actionResult?.success === false && actionResult?.error && (
            <Alert variant="danger"><ExclamationCircleFill /> {actionResult?.error}</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isProcessing}>
            {isReadOnly ? "No, Keep It" : "Cancel"}
          </Button>
          <Button type="submit" variant="primary" disabled={isProcessing}>
            {isReadOnly ? "Yes, Delete It" : "Save"}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default Participant;
