import {httpsCallableFromURL} from "firebase/functions";
import { ActionFunctionArgs } from "../App";
import { Giver, Mailbox, Receiver, MessageSender, SendMessageRequest } from "common";
import {db, functions, toDate} from "./firebase";
import {MailState, Message} from "../types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {Epic} from "redux-observable";
import {ActionTypes, AppThunk, RootState} from "./store";
import {Observable, combineLatest, of} from "rxjs";
import {filter, withLatestFrom, map, switchMap} from "rxjs/operators";
import {updateUser} from "./auth";
import {updateGiftExchange} from "./giftExchange";
import {collection, onSnapshot, query, where, updateDoc, doc} from "firebase/firestore";

const initialState: MailState = {
  isInitializing: true,
  inbox: [],
  outbox: [],
  unreadMessageCount: 0,
};

export const slice = createSlice({
  name: 'mail',
  initialState,
  reducers: {
    addToInbox: (state: MailState, action: PayloadAction<Message[]>) => {
      const {inbox} = state;
      const {payload: newMessages} = action;

      // Remove any dups
      state.inbox = [
        ...newMessages,
        ...inbox.filter((em) => newMessages.findIndex((nm: Message) => em.id === nm.id) === -1)
      ].sort((m1: Message, m2: Message) => m2.sentOn.getTime() - m1.sentOn.getTime());
      state.unreadMessageCount = state.inbox.filter((m) => !m.read).length;
    },
    addToOutbox: (state: MailState, action: PayloadAction<Message[]>) => {
      const {outbox} = state;
      const {payload: newMessages} = action;

      // Remove any dups
      state.outbox = [
        ...newMessages,
        ...outbox.filter((em) => newMessages.findIndex((nm: Message) => em.id === nm.id) === -1)
      ].sort((m1: Message, m2: Message) => m2.sentOn.getTime() - m1.sentOn.getTime());
    },
    deleteFromInbox: (state: MailState, action: PayloadAction<string[]>) => {
      const {inbox} = state;
      const {payload: messagesToDelete} = action;

      // Remove receiver if id is in the action list
      state.inbox = inbox.filter((m: Message) => !messagesToDelete.includes(m.id));
      state.unreadMessageCount = state.inbox.filter((m) => !m.read).length;
    },
    deleteFromOutbox: (state: MailState, action: PayloadAction<string[]>) => {
      const {outbox} = state;
      const {payload: messagesToDelete} = action;

      // Remove receiver if id is in the action list
      state.outbox = outbox.filter((m: Message) => !messagesToDelete.includes(m.id));
    },
    initComplete: (state: MailState, action: PayloadAction<undefined>) => {
      state.isInitializing = false;
    },
  },
});

export const {
  addToInbox,
  addToOutbox,
  deleteFromInbox,
  deleteFromOutbox,
  initComplete,
} = slice.actions;

export type MailActionsTypes = 
  ReturnType<typeof addToInbox> |
  ReturnType<typeof addToOutbox> |
  ReturnType<typeof deleteFromInbox> |
  ReturnType<typeof deleteFromOutbox> |
  ReturnType<typeof initComplete>
;

export const loadMailEpic: Epic<ActionTypes, ActionTypes, RootState> = (action$, state$) => {
  const user$ = action$.pipe(filter(updateUser.match));
  const giftExchange$ = action$.pipe(filter(updateGiftExchange.match));
  return combineLatest([user$, giftExchange$]).pipe(
    withLatestFrom(state$),
    map(([,state]) => state),
    filter((state) => !state.auth.isInitializing && !state.giftExchange.isInitializing),
    filter((state) => state.auth.isLoggedIn),
    filter((state) => state.giftExchange.isActive),
    switchMap((state) =>
      (state.auth.user?.canAccess?.length || 0) === 0 ?
      of(initComplete()) :
      new Observable<MailActionsTypes>((subscriber) => {
        const {canAccess = []} = state.auth.user || {};
        const messagesCollection = collection(db, "gift-exchanges", String(state.giftExchange.currentGiftExchange?.gid), "messages");
        const messagesQuery = query(messagesCollection, where("pid", "in", canAccess));

        const unsubscribe = onSnapshot(messagesQuery, {
          next: (snapshot) => {
            const inboxAdds: Message[] = [];
            const outboxAdds: Message[] = [];
            const inboxDeletes: string[] = [];
            const outboxDeletes: string[] = [];
            snapshot.docChanges().forEach((change) => {
              const {doc} = change;
              const data = doc.data();
              const message: Message = {
                ...data as Message,
                id: doc.id,
                sentOn: toDate(data.sentOn),
              };

              if (change.type === "removed") {
                if (message.mailbox === Mailbox.Inbox) {
                  inboxDeletes.push(doc.id);
                } else {
                  outboxDeletes.push(doc.id);
                }
              } else {
                if (message.mailbox === Mailbox.Inbox) {
                  inboxAdds.push(message);
                } else {
                  outboxAdds.push(message);
                }
              }
            });
            if (inboxAdds.length > 0) {
              subscriber.next(addToInbox(inboxAdds));
            }
            if (outboxAdds.length > 0) {
              subscriber.next(addToOutbox(outboxAdds));
            }
            if (inboxDeletes.length > 0) {
              subscriber.next(deleteFromInbox(inboxDeletes));
            }
            if (outboxDeletes.length > 0) {
              subscriber.next(deleteFromOutbox(outboxDeletes));
            }
            subscriber.next(initComplete());
          },
          error: (error) => {
            console.error("Mail query errored", error);
            subscriber.error(error);
          },
          complete: () => {
            console.warn("Mail query closed");
            subscriber.complete();
          },
        });
        return unsubscribe;
      })
    ),
  );
};

export type MessageLoaderData = {
    receiver?: Receiver;
    giver?: Giver;
};

export type MessageActionData = {
    body: string;
};

const sendMessageFunction = httpsCallableFromURL<SendMessageRequest, void>(
  functions,
  '/api/sendMessage',
);

export async function sendMessage({ params, request }: ActionFunctionArgs) {
  try{
    const {
        receiver,
        giver,
    } = params;
    const {
        body,
    } = Object.fromEntries(await request.formData()) as MessageActionData;

    let pid;
    if (receiver === undefined) {
      if (giver === undefined) {
        throw new Error("Must send parameter 'receiver' or 'giver'");
      }
      pid = giver;
    } else {
      if (giver !== undefined) {
        throw new Error("Cannot send both 'receiver' or 'giver' parameters");
      }
      pid = receiver;
    }

    if (request.method !== "POST") {
      throw new Error(`Unsupported method ${request.method}`);
    }

    await sendMessageFunction({
      body,
      pid,
      sentFrom: giver === undefined ?
        MessageSender.Receiver :
        MessageSender.Giver,
    });
    return {
      success: true,
    };
  } catch (err: any) {
    console.error(err.message);
    return {
      success: false,
      error: "Oops. There was a system glitch.",
    }
  }
}

export const markMessageAsRead = (id: string): AppThunk => async (dispatch, getState) => {
  const {gid} = getState().giftExchange.currentGiftExchange || {};
  const messagesCollection = collection(db, "gift-exchanges", String(gid), "messages");
  await updateDoc(doc(messagesCollection, id), {read: true});

};

export default slice.reducer;
