import {deleteField, doc, updateDoc} from "firebase/firestore";
import {ActionFunctionArgs, LoaderFunctionArgs} from "../App";
import {Receiver, AttendanceOptions} from "../types";
import {db} from "./firebase";

export type ReceiverLoaderData = {
  receiver: Receiver;
};

export type AttendanceStatusActionData = {
  attending: AttendanceOptions;
  deliveryInstructions?: string;
};

export function loadReceiver({params, state, adminMode}: LoaderFunctionArgs): Promise<ReceiverLoaderData> {
  return new Promise((resolve, reject) => {
    const {receiver: receiverId} = params;

    if (receiverId === undefined) {
      const msg = "Missing parameter 'receiver'";
      console.error(`loadReceiver: ${msg}`);
      return reject(msg);
    }

    const {receivers} = adminMode ? state.allReceivers : state.myReceivers;
    const receiver = receivers.find((r: Receiver) => r.pid === receiverId);
    if (receiver === undefined) {
      const msg = `Unknown receiver '${receiverId}'`;
      console.error(`loadReceiver: ${msg}`);
      return reject(msg);
    }

    resolve({receiver});
  });
};

export async function modifyAttendanceStatusAction({params, request, state, adminMode}: ActionFunctionArgs) {
  const {
    receiver: receiverId,
  } = params;
  const {
    attending,
    deliveryInstructions,
  } = Object.fromEntries(await request.formData()) as AttendanceStatusActionData;

  let error;

  if (receiverId === undefined) throw new Error("Missing required param receiverId");
  const {receivers} = adminMode ? state.allReceivers : state.myReceivers;

  if (request.method === "PUT") {
    const updated = receivers.find((r: Receiver) => r.pid === receiverId);
    if (updated === undefined) throw new Error(`Unable to find recevier ${receiverId}`);
    const receiverDocRef = doc(db, "gift-exchanges", String(state.giftExchange.currentGiftExchange?.gid), "receivers", receiverId);
    await updateDoc(receiverDocRef, {
      attending,
      deliveryInstructions: deliveryInstructions || deleteField(),
    });
  } else {
    error = `Unsupported method ${request.method}`
  }

  return {
    success: !error,
    error,
  };
}
