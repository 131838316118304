import React, {FormEventHandler, useCallback, useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import {ExclamationCircleFill} from "react-bootstrap-icons";

import {Form as RouterForm, useActionData, useLoaderData, useNavigate} from "react-router-dom";
import {ReceiverLoaderData} from "../data/receivers";
import {AttendanceOptions} from "common/dist/types/core";

const AttendanceStatus = () => {
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);
  const {
    receiver,
  } = useLoaderData() as ReceiverLoaderData;
  const actionResult = useActionData() as any;
  const [isProcessing, setIsProcessing] = useState(false);

  const handleClose = useCallback(() => {
    navigate("..");
  }, [navigate]);

  useEffect(() => {
    if (actionResult?.success === true) {
      handleClose();
    } else {
      setIsProcessing(false);
    }
  }, [actionResult, handleClose]);

  type FormObject = {
    checkValidity: () => boolean;
  }

  const handleSubmit: FormEventHandler<FormObject> = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      setIsProcessing(true);
    } 
    setValidated(true);
  };

  const showOrHideDeliveryInstructions = () => {
    const attendingElements = document.getElementsByName('attending');
    let attending = "";
    for (let i = 0; i < attendingElements.length; i++) {
      const attendingElement = attendingElements[i] as HTMLInputElement;
      if (attendingElement.checked) {
        attending = attendingElement.value;
        break;
      }
    }

    const deliveryInstructionsWrapperElement = document.getElementById('attendanceDeliveryInstructions');
    const deliveryInstructionsElement = document.getElementById('deliveryInstructions') as HTMLInputElement;
    if (deliveryInstructionsWrapperElement !== null) {
      const hide = attending === "" || attending === "InPerson";
      deliveryInstructionsWrapperElement.hidden = hide;
      deliveryInstructionsElement.disabled = hide;
    }
  };

  useEffect(() => showOrHideDeliveryInstructions());

  return (
    <Modal show={true} onHide={handleClose}>
      <Form as={RouterForm} method="put" noValidate validated={validated} onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            Update Attendance Status
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="attendanceAttending">
            <Form.Label>Attending the celebration?</Form.Label>
            <Form.Check>
              <Form.Check.Input
                type="radio"
                id="attending-InPerson"
                name="attending"
                value="InPerson"
                defaultChecked={receiver.attending === AttendanceOptions.InPerson}
                onChange={showOrHideDeliveryInstructions}
                required
                disabled={isProcessing}
              />
              <Form.Check.Label>Yes, In Person</Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Input
                type="radio"
                id="attending-Remote"
                name="attending"
                value="Remote"
                defaultChecked={receiver.attending === AttendanceOptions.Remote}
                onChange={showOrHideDeliveryInstructions}
                required
                disabled={isProcessing}
              />
              <Form.Check.Label>Yes, Remotely</Form.Check.Label>
            </Form.Check>
            <Form.Check>
              <Form.Check.Input
                type="radio"
                id="attending-NotAttending"
                name="attending"
                value="NotAttending"
                defaultChecked={receiver.attending === AttendanceOptions.NotAttending}
                onChange={showOrHideDeliveryInstructions}
                required
                disabled={isProcessing}
              />
              <Form.Check.Label>No, Not Able to Attend</Form.Check.Label>
              <Form.Control.Feedback type="invalid">
                You must select either In Person, Remote, or Not Attending.
              </Form.Control.Feedback>
            </Form.Check>
          </Form.Group>
          <Form.Group
            className="mb-3"
            controlId="attendanceDeliveryInstructions"
            id="attendanceDeliveryInstructions"
          >
            <Form.Label>Delivery Instructions</Form.Label>
            <Form.Control
                type="input"
                id="deliveryInstructions"
                name="deliveryInstructions"
                defaultValue={receiver.deliveryInstructions}
                rows={4}
                required
                as="textarea"
                disabled={isProcessing}
                />
            <Form.Control.Feedback type="invalid">
              Delivery instructions are required when you will be Remote or Not Attending.
            </Form.Control.Feedback>
            <Form.Text>
              Add your address or any other details your giver should know
              in order to deliver your gift to you.
            </Form.Text>
          </Form.Group>
          {actionResult?.success === false && actionResult?.error && (
            <Alert variant="danger"><ExclamationCircleFill /> {actionResult?.error}</Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose} disabled={isProcessing}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={isProcessing}
          >
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AttendanceStatus;