import React from "react";

import {ActiveParticipant, AttendanceOptions} from "common";
import Badge from 'react-bootstrap/Badge';

interface AttendanceBadgeParameters {
  participant: ActiveParticipant;
};

const AttendanceBadge = (params: AttendanceBadgeParameters) => {
  const {participant} = params;

  switch (participant.attending) {
    case AttendanceOptions.InPerson:
      return (<Badge bg="error" pill>Attending In Person</Badge>);
    case AttendanceOptions.Remote:
      return (<Badge bg="error" pill>Attending Remote</Badge>);
    case AttendanceOptions.NotAttending:
      return (<Badge bg="error" pill>Not Attending</Badge>);
  }
  return (<></>);
};

export default AttendanceBadge;

