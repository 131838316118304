import React, {useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup'
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import {
  Trash,
  Pencil,
  ArrowRight,
} from 'react-bootstrap-icons';

import {Receiver, GiftIdea} from "common";
import {Outlet, useNavigate} from 'react-router-dom';
import AttendanceBadge from "../components/AttendanceBadge";
import {useAppSelector} from "../data/store";

interface GiftIdeasParams {
  adminMode?: boolean;
};

const GiftIdeas = ({adminMode = false}: GiftIdeasParams) => {
  const navigate = useNavigate();
  const state = useAppSelector((state) => state);
  const {receivers} = adminMode === true ? state.allReceivers : state.myReceivers;
  const {user} = useAppSelector((state) => state.auth);
  const giftExchangeState = useAppSelector((state) => state.giftExchange);

  useEffect(() => {
    if (!giftExchangeState.isInitializing && !giftExchangeState.isActive) {
      navigate("/");
    }
  }, [giftExchangeState.isActive, giftExchangeState.isInitializing, navigate]);

  return (<>
    {receivers.map((receiver: Receiver) => (
      <Card
        bg="primary"
        text="light"
        className="mb-3"
        key={receiver.pid}
      >
        <Card.Header className="d-flex justify-content-between align-items-start">
          <div className="me-auto">
            {receiver.name}'s Gift Ideas
          </div>
          <AttendanceBadge participant={receiver} />
          <Badge bg="secondary" pill>${receiver.spendingLimit} Limit</Badge>
        </Card.Header>
        <ListGroup className="list-group-flush">
          {(receiver.giftIdeas || []).length === 0 && (
            <ListGroup.Item variant="secondary">
              You have not submitted any gift ideas for {receiver.name}.  Please add a few items to
              help your giver get in the holiday spirit.
            </ListGroup.Item>
          )}
          {(receiver.giftIdeas || []).length > 0 && receiver.attending === undefined && (
            <ListGroup.Item variant="secondary">
              You have not submitted {receiver.name}'s attendance status.  Please update to let your
              giver know where to deliver the gifts.
            </ListGroup.Item>
          )}
          {receiver.giftIdeas?.map((idea: GiftIdea) => (
            <ListGroup.Item
              key={idea.id}
            >
              <div className="d-flex justify-content-between align-items-start">
                <div className="h-100 d-inline-block align-baseline">
                  {idea.description}
                  {idea.link && (
                    <a
                      href={idea.link}
                      target="_blank"
                      rel="noreferrer"
                      className="icon-link inline-action-link"
                    >
                      Open Link <ArrowRight />
                    </a>
                  )}
                </div>
                <ButtonGroup size="sm">
                  <Button variant="outline-primary" onClick={() => navigate(`edit-idea/${receiver.pid}/${idea.id}`)}><Pencil /></Button>
                  <Button variant="outline-primary" onClick={() => navigate(`delete-idea/${receiver.pid}/${idea.id}`)}><Trash /></Button>
                </ButtonGroup>
              </div>
            </ListGroup.Item>
          ))}
          <ListGroup.Item>
            <ButtonGroup size="sm">
              <Button variant="outline-primary" onClick={() => navigate(`new-idea/${receiver.pid}`)}>
                Add Gift Idea
              </Button>
              <Button variant="outline-primary" onClick={() => navigate(`attendance-status/${receiver.pid}`)}>
                Update Attendance Status
              </Button>
              {adminMode && user?.isAdmin && (
                <Button variant="outline-primary" onClick={() => navigate(`edit-participant/${receiver.pid}`)}>
                  Edit Name / Email
                </Button>
              )}
              {!adminMode && (
                <Button variant="outline-primary" onClick={() => navigate(`send-message-to-giver/${receiver.pid}`)}>
                  Send Message to Giver
                </Button>
              )}
            </ButtonGroup>
          </ListGroup.Item>
        </ListGroup>
      </Card>
    ))}
    <Outlet />
  </>);
};

export default GiftIdeas;
