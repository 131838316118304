import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useLoaderData, useNavigate } from "react-router-dom";
import { GiverLoaderData } from "../data/my-givers";
import Table from "react-bootstrap/Table";

const ShowHistory = () => {
    const navigate = useNavigate();
    const { giver } = useLoaderData() as GiverLoaderData;
    const { givingHistory = {} } = giver;

    const handleClose = () => {
        navigate("..");
    };

    return (
        <Modal show={true} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{giver.name}'s History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Year</th>
                            <th>Gave To</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(givingHistory).map(([year, gaveTo]) => (
                            <tr key={`history-${year}`}>
                                <td>{year}</td>
                                <td>{gaveTo}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShowHistory;
